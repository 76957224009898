/** @jsxImportSource @ac/library-utils/dist/web-components/wc-jsx */
import { Link } from 'react-router-dom';

import { MenuItemType } from '@ac/web-components';

import './MenuDropdownItem.scss';

type MenuDropdownItemProps = {
  title: string;
  options: MenuDropdownItemOption[];
  onClickCallback: () => void;
};

export type MenuDropdownItemOption = {
  name: string;
  url: string;
};

export const MenuDropdownItem = ({
  title,
  options,
  onClickCallback,
}: MenuDropdownItemProps): JSX.Element => {
  return (
    <>
      <ac-menu-item type={MenuItemType.header} label={title} />
      {options.map((option) => (
        <Link
          key={option.name}
          className="menu-dropdown-link display-block ac-padding-left-lg ac-padding-vertical-xs"
          to={option.url}
          onClick={onClickCallback}
        >
          <ac-text key={option.name} class="menu-dropdown-item">
            {option.name}
          </ac-text>
        </Link>
      ))}
    </>
  );
};
