/** @jsxImportSource @ac/library-utils/dist/web-components/wc-jsx */
import { getInitials } from '@ac/library-api';
import { LoginService } from '@ac/library-utils/dist/services';
import { MenuButtonSize } from '@ac/web-components';

interface Props {
  firstName?: string;
  lastName?: string;
}

export const UserDropdownTrigger = ({
  firstName,
  lastName,
}: Props): JSX.Element => {
  const initials = getInitials(
    firstName ?? LoginService.authData()?.userType ?? '',
    lastName ?? LoginService.authData()?.userType ?? ''
  );

  return (
    <ac-menu-button size={MenuButtonSize.trigger} disableHover>
      {initials}
    </ac-menu-button>
  );
};
