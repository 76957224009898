/** @jsxImportSource @ac/library-utils/dist/web-components/wc-jsx */
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import {
  AlignItems,
  IconName,
  JustifyContent,
  MenuBarSlot,
  MenuBarTheme,
  Size,
  TextColor,
  TextSize,
  TextWeight,
} from '@ac/web-components';

import { MenuDropdown } from '@translations/features/application/features/menu/MenuDropdown/MenuDropdown';
import { UserDropdown } from '@translations/features/application/features/menu/UserDropdown/UserDropdown';

import './Menu.scss';

export type Breadcrumb = {
  path?: string;
  name: string;
};

interface MenuProps {
  title: string;
  breadcrumbs?: Breadcrumb[];
}

export const Menu = ({ breadcrumbs, title }: MenuProps): JSX.Element => {
  const navigate = useNavigate();
  const [isDropdownVisible, setIsDropdownVisible] = useState(false);

  const handleBreadcrumbClick = (path: Breadcrumb['path']): void => {
    if (path) {
      navigate(path);
    }
  };

  return (
    <>
      <ac-menu-bar
        id="menu-v2-bar"
        theme={MenuBarTheme.light}
        isOpen={isDropdownVisible}
        onHamburgerClickCallback={(): void =>
          setIsDropdownVisible(!isDropdownVisible)
        }
      >
        <ac-flex
          slot={MenuBarSlot.pageName}
          justifyContent={JustifyContent.flexStart}
          alignItems={AlignItems.center}
        >
          <ac-text
            color={TextColor.white}
            class="ac-spacing-left-sm"
            weight={TextWeight.semibold}
            size={TextSize.lg}
          >
            {title}
          </ac-text>
        </ac-flex>

        <ac-flex
          slot={MenuBarSlot.breadcrumbs}
          justifyContent={JustifyContent.flexStart}
          alignItems={AlignItems.center}
        >
          {breadcrumbs?.map(({ name: itemName, path: itemPath }, index) => (
            <>
              {index !== breadcrumbs.length && index !== 0 && (
                <ac-icon
                  icon={IconName.actionRight}
                  size={Size.xs}
                  class="breadcrumbs-divider"
                />
              )}
              <ac-menu-button
                transparent
                key={itemName}
                disableHover={!itemPath}
                class={!itemPath ? 'no-action-breadcrumb-button' : ''}
                onClickCallback={(): void => handleBreadcrumbClick(itemPath)}
              >
                <span>{itemName}</span>
              </ac-menu-button>
            </>
          ))}
        </ac-flex>

        <ac-flex
          alignItems={AlignItems.center}
          justifyContent={JustifyContent.center}
          slot={MenuBarSlot.content}
        >
          <UserDropdown />
        </ac-flex>
      </ac-menu-bar>

      <MenuDropdown
        isVisible={isDropdownVisible}
        onCloseCallback={(): void => setIsDropdownVisible(false)}
      />
    </>
  );
};
