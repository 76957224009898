/** @jsxImportSource @ac/library-utils/dist/web-components/wc-jsx */
import { isDefined } from '@ac/library-utils/dist/utils';
import {
  AlignItems,
  FlexDirection,
  TextColor,
  TextWeight,
} from '@ac/web-components';

interface Props {
  firstName?: string;
  lastName?: string;
  email?: string;
}

export const UserDropdownHeader = ({
  firstName,
  lastName,
  email,
}: Props): JSX.Element => {
  const formattedName = [lastName, firstName].filter(Boolean).join(', ');
  const hasEmail = isDefined(email);

  return (
    <ac-flex
      direction={FlexDirection.column}
      alignItems={AlignItems.flexStart}
      class="user-dropdown-header ac-gap-xs"
    >
      <ac-text weight={TextWeight.semibold}>{formattedName}</ac-text>
      {hasEmail && <ac-text color={TextColor.lightGray}>{email}</ac-text>}
    </ac-flex>
  );
};
