/** @jsxImportSource @ac/library-utils/dist/web-components/wc-jsx */
import { useTranslation } from 'react-i18next';

import { MenuSlot } from '@ac/web-components';

import { APPLICATION_ROUTES } from '@translations/features/application/config/routes.config';
import { MenuDropdownItem } from '@translations/features/application/features/menu/MenuDropdownItem/MenuDropdownItem';

type Props = {
  isVisible: boolean;
  onCloseCallback: () => void;
};

export const MenuDropdown = ({
  isVisible,
  onCloseCallback,
}: Props): JSX.Element | null => {
  const { t } = useTranslation();
  const options = [
    {
      title: t('APP.MENU.ITEMS.TRANSLATIONS.TITLE'),
      options: [
        {
          name: t('APP.MENU.ITEMS.TRANSLATIONS.OPTIONS.COMPLETENESS'),
          url: APPLICATION_ROUTES.DASHBOARD,
        },
      ],
    },
  ];

  return !isVisible ? null : (
    <ac-menu onOutsideClickCallback={onCloseCallback} isVisible={isVisible}>
      <ac-menu-group slot={MenuSlot.left}>
        {options.map((option) => (
          <MenuDropdownItem
            key={option.title}
            title={option.title}
            options={option.options}
            onClickCallback={onCloseCallback}
          />
        ))}
      </ac-menu-group>
    </ac-menu>
  );
};
